<template>
  <div class="row q-pt-xl justify-center bg-grey-2" style="height: 100vh;">
    <div>
      <q-card style="width: 300px; ">
        <q-tabs
          dense
          v-model="tab"
          indicator-color="orange"
          align="center"
          inline-label
          active-color="white"
          class="bg-blue-12 text-blue-11 shadow-2"
        >
          <q-tab
            @click="jenjang = 'SMP PA'"
            name="SMP PERGURUAN ISLAM AR RISALAH"
            label="SMP PA"
          />
          <q-tab
            @click="jenjang = 'SMP PI'"
            name="SMP PUTRI PERGURUAN ISLAM AR RISALAH"
            label="SMP PI"
          />
          <q-tab
            @click="jenjang = 'MA'"
            name="MAS PERGURUAN ISLAM AR RISALAH"
            label="MA"
          />
        </q-tabs>
        <q-card-section class="text-center">
          <q-img
            src="./img/Logo Perguruan Vertikal.png"
            contain
            style="max-height:200px"
            :ratio="4 / 3"
          ></q-img>
          <br />
          <br />
          <a class="text-h6 text-primary">Portal Akademik</a>
          <br />
          <a class="text-h6 text-primary">{{ tab }}</a>
          <br />
          <a class="text-caption text-grey-6">Masukkan username dan password</a>
        </q-card-section>
        <q-card-section class="q-gutter-sm q-mb-sm">
          <q-input
            outlined
            v-model="user.username"
            @keyup.enter="$refs.input_password.focus()"
            ref="input_username"
          >
            <template v-slot:prepend>
              <q-icon name="person" />
            </template>
          </q-input>
          <q-input
            outlined
            type="password"
            v-model="user.password"
            @keyup.enter="login"
            ref="input_password"
          >
            <template v-slot:prepend>
              <q-icon name="lock" />
            </template>
          </q-input>
        </q-card-section>
        <q-btn
          color="primary"
          class="full-width no-border-radius"
          @click="login()"
          >login</q-btn
        >
      </q-card>
    </div>
    <q-dialog v-model="alert" persistent>
      <q-card>
        <q-card-section class="row items-center">
          <span>
            Tidak ada Tahun Ajaran berjalan pada {{ jenjang }}. Silahkan Hubungi
            Civitas Akademik
          </span>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="close" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import General from "@/mixins/General.js";
import { mapActions, mapMutations } from "vuex";
export default {
  mixins: [General],
  data() {
    return {
      alert: false,
      jenjang: "SMP PA",
      tab: "SMP PERGURUAN ISLAM AR RISALAH",
      user: {
        username: "",
        password: "",
      },
    };
  },
  mounted() {
    this.$refs.input_username.focus();
  },
  methods: {
    ...mapActions(["sendCurrentUser"]),
    ...mapMutations(["setRole"]),
    db_filter(x) {
      if (x == "1") {
        return true;
      } else {
        return false;
      }
    },
    async roleGenerator() {
      let resp = await this.$http.get("/user_data", this.$hlp.getHeader());
      let data = {
        is_bk: this.db_filter(resp.data.is_guru_bk),
        is_mapel: this.db_filter(resp.data.is_guru_mapel),
        is_kepsek: this.db_filter(resp.data.is_kepala_sekolah),
        is_ekskul: this.db_filter(resp.data.is_pembina_ekskul),
        is_walas: this.db_filter(resp.data.is_wali_kelas),
        is_super_user: this.db_filter(resp.data.is_super_user),
        is_kurikulum: this.db_filter(resp.data.is_kurikulum),
      };
      this.setRole(data);
      await this.sendCurrentUser();
    },
    async login() {
      var user = this.prepareUser();
      try {
        let resp = await this.$http.post("/login", user);
        // this.$emit("hasLogin");
        await this.getTahunAjar(resp.data);
        await this.roleGenerator();
        this.notifyPositive("proses login sukses");

        this.$router.push("/");
      } catch (err) {
        this.notifyNegative("proses login gagal");
      }
    },
    async getTahunAjar(data) {
      let resp = await this.$http.get(`/tahun_ajaran/${this.jenjang}`);
      if (resp.data.length > 0) {
        this.tahun_ajaran = resp.data[0];
        await this.writeLS(data, resp.data[0]);
      } else {
        this.alert = true;
      }
    },
    prepareUser() {
      let md5 = require("md5");
      let user = this.copyObj(this.user);
      user.password = md5(user.password);
      user.jenjang = this.jenjang;
      return user;
    },
    async writeLS(user, tahun_ajar) {
      await localStorage.setItem("token", user);
      await localStorage.setItem("jenjang", this.jenjang);
      await localStorage.setItem("id_tahun_ajaran", tahun_ajar.id);
      await localStorage.setItem(
        "nama_tahun_ajaran",
        `${tahun_ajar.nama} - Semester ${tahun_ajar.semester}`
      );
    },
  },
};
</script>
